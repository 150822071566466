import React, { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'next-i18next';

import { REGEX } from '../../constants/index';

type PasswordForgotProps = {
  email: string;
  setEmail: Function;
  setContext: Function;
};

const PasswordForgot = ({ email, setEmail, setContext }: PasswordForgotProps) => {
  const [emailError, setEmailError] = useState<string>('');
  const { t } = useTranslation('components');

  const forgotPassword = async (event: FormEvent) => {
    event.preventDefault();

    if (!REGEX.EMAIL.test(email)) {
      return setEmailError(t('PASSWORD_FORGOT.EMAIL_ERROR_MESSAGE'));
    }

    const successMessage = () => {
      setContext('newPassword');
      toast.success(t('PASSWORD_FORGOT.RESET_SUCCESS_MESSAGE'));
    };

    try {
      await Auth.forgotPassword(email);
      successMessage();
    } catch (e) {
      // successful request EVEN if failer for Auth.forgotPassword() to prevent giving further insight to malicious attackers
      successMessage();
    }
  };

  return (
    <form onSubmit={forgotPassword} method="post">
      <p className="mt-6 mb-6">{t('PASSWORD_FORGOT.HEADER')}</p>
      <div className="relative">
        <label>
          {t('PASSWORD_FORGOT.EMAIL_LABEL')}
          <input
            type="text"
            name="password"
            className={emailError ? 'error' : ''}
            required
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            autoCapitalize="off"
            autoComplete="email"
          />
        </label>
      </div>
      <button disabled={!email.length} className="primary full-width mt-6">
        {t('PASSWORD_FORGOT.RESET_PASSWORD_CTA')}
      </button>
    </form>
  );
};

export default PasswordForgot;
