import { useState, FormEvent, useContext } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import { Auth, API } from 'aws-amplify';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';
import { GetSubscriptionHundoQuery } from '../../API';
import { getSubscriptionHundo } from '../../graphql/queries';
import warningIcon from '/public/icons/warning.svg';
import styles from './index.module.scss';
import { getAuthErrorMessage, Mixpanel, GA } from '../../shared/utils';
import { REGEX } from '../../constants';
import { fetchUserData } from '../../shared/utils/player';
import { Player } from '../../types';
import { AuthContext } from '../../context/auth/context';

type AccountLoginProps = {
  email: string;
  setEmail: Function;
  setContext: Function;
  promoCode?: string;
};

const AccountLogin = ({ email, setEmail, setContext, promoCode }: AccountLoginProps) => {
  const { setUser } = useContext<{ user: Player; setUser: Function }>(AuthContext);
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const { push } = useRouter();
  const { t } = useTranslation('components');

  const promoCodeURL = promoCode ? `?PROMO_CODE=${promoCode}` : '';

  const signIn = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (!REGEX.EMAIL.test(email)) {
        return setEmailError(t('ACCOUNT_LOGIN.EMAIL_ERROR_MESSAGE'));
      }

      Auth.signOut();
      const { username } = await Auth.signIn(email, password);

      fetchUserData(setUser, username);

      GA.event('user', 'sign_in', 'Player signed in');

      const { data } = (await API.graphql({
        query: getSubscriptionHundo,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        variables: {
          id: email,
        },
      })) as { data: GetSubscriptionHundoQuery };

      if (data?.getSubscriptionHundo) {
        Mixpanel.identify(email);
        Mixpanel.track('Successfully logged in', email);

        push('/profile');
      } else {
        push(`/subscription${promoCodeURL}`);
      }
    } catch (error: unknown) {
      const stringError = String(error);

      if (stringError.includes('UserNotConfirmedException')) {
        return push({ pathname: '/register', query: { context: 'verify', email } });
      }

      if (stringError.includes('InvalidPasswordException')) {
        return setPasswordError(t('ACCOUNT_LOGIN.WRONG_PASSWORD_ERROR_MESSAGE'));
      }

      const errorMessage = getAuthErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  return (
    <form onSubmit={signIn}>
      <div className="pb-10">
        <label>
          {t('ACCOUNT_LOGIN.EMAIL_LABEL')}
          <input
            type="text"
            name="email"
            required
            value={email}
            placeholder={t('ACCOUNT_LOGIN.EMAIL_PLACEHOLDER')}
            className={emailError && 'error'}
            onChange={(e) => setEmail(e.currentTarget.value)}
            autoComplete="email"
            autoCapitalize="off"
          />
        </label>

        <div className="relative mt-6">
          <label>
            {t('ACCOUNT_LOGIN.PASSWORD_LABEL')}
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              required
              value={password}
              placeholder={t('ACCOUNT_LOGIN.PASSWORD_PLACEHOLDER')}
              className={passwordError && 'error'}
              onChange={(e) => setPassword(e.currentTarget.value)}
              autoComplete="current-password"
            />
          </label>
          <div className="flex justify-between mb-3">
            <button className="naked underline" onClick={() => setShowPassword(!showPassword)} type="button">
              {showPassword ? t('ACCOUNT_LOGIN.HIDE_PASSWORD') : t('ACCOUNT_LOGIN.SHOW_PASSWORD')}
            </button>
            <button
              onClick={() => {
                setContext('forgotPassword');
                setEmail('');
                setPassword('');
              }}
              className="naked underline"
              type="button"
            >
              {t('ACCOUNT_LOGIN.FORGOTTEN_PASSWORD_CTA')}
            </button>
          </div>
        </div>
        {emailError && (
          <div className="flex pb-4">
            <Image alt="Warning icon" src={warningIcon} />
            <p className="error  pl-4" dangerouslySetInnerHTML={{ __html: emailError }} />
          </div>
        )}
        {passwordError && (
          <div className="flex pb-4">
            <Image alt="Warning icon" src={warningIcon} />
            <p className="error  pl-4" dangerouslySetInnerHTML={{ __html: passwordError }} />
          </div>
        )}
      </div>

      <button className="primary full-width">{t('ACCOUNT_LOGIN.LOG_IN_CTA')}</button>
      <p className="mt-6 mb-8 text-center">
        {t('ACCOUNT_LOGIN.SIGN_UP_LABEL')}
        <Link href={`/register${promoCodeURL}`}>
          <a>
            <span className={styles.link}>{t('ACCOUNT_LOGIN.SIGN_UP_CTA')}</span>
          </a>
        </Link>
      </p>
    </form>
  );
};

export default AccountLogin;
