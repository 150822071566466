import type { GetServerSidePropsContext } from 'next';
import Head from 'next/head';
import { useState } from 'react';
import dynamic from 'next/dynamic';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { withSSRContext } from 'aws-amplify';

import styles from './index.module.scss';
import PasswordNew from '../../components/PasswordNew';
import AccountLogin from '../../components/AccountLogin';
import PasswordForgot from '../../components/PasswordForgot';
import AccountImage from '../../components/AccountImage';

const AccountNavBar = dynamic(() => import('../../components/AccountNavBar'), { ssr: false });

type LoginProps = {
  PROMO_CODE?: string;
};

const Login = ({ PROMO_CODE }: LoginProps) => {
  const [context, setContext] = useState<string>('login');
  const [email, setEmail] = useState<string>('');
  const { t } = useTranslation('login');

  return (
    <div>
      <Head>
        <title>{t('LOGIN_PAGE_TITLE')}</title>
        <link rel="icon" href="/hundo-favicon.png" />
        <meta key="description" name="description" content={t('LOGIN_PAGE_DESCRIPTION')} />
        <meta key="og-title" property="og:title" content={t('LOGIN_PAGE_TITLE')} />
        <meta key="og-description" property="og:description" content={t('LOGIN_PAGE_TITLE')} />
        <meta key="og-url" property="og:url" content="https://hundo.xyz/login" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://uploads-ssl.webflow.com/60a388b4768e9223fb70ca2b/635c7a25f51427ab9379c247_Social-link-share-CAREERCON22.webp"
        />
        <meta property="og:type" content="website" />
        <meta key="twitter-title" name="twitter:title" content={t('LOGIN_PAGE_TITLE')} />
        <meta key="twitter-description" name="twitter:description" content={t('LOGIN_PAGE_DESCRIPTION')} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@hundo.xyz" />
        <meta
          name="twitter:image"
          content="https://uploads-ssl.webflow.com/60a388b4768e9223fb70ca2b/635c7a25f51427ab9379c247_Social-link-share-CAREERCON22.webp"
        />
      </Head>

      <AccountNavBar promoCode={PROMO_CODE} />
      <main className={styles.container}>
        <div className={styles['inner-container']}>
          <div className={styles.authentication}>
            {context.includes('login') && <h1>{t('LOGIN_PAGE_DEFAULT_HEADER')}</h1>}
            {context.includes('forgotPassword') && <h1>{t('LOGIN_PAGE_FORGOTTEN_PASSWORD_HEADER')}</h1>}
            {context.includes('newPassword') && <h1>{t('LOGIN_PAGE_CREATE_NEW_PASSWORD_HEADER')}</h1>}

            <>
              {context.includes('login') && (
                <AccountLogin email={email} setEmail={setEmail} setContext={setContext} promoCode={PROMO_CODE} />
              )}

              {context.includes('forgotPassword') && (
                <PasswordForgot email={email} setEmail={setEmail} setContext={setContext} />
              )}

              {context.includes('newPassword') && <PasswordNew email={email} setContext={setContext} />}
            </>
          </div>
        </div>
        <AccountImage />
      </main>
    </div>
  );
};

export const getServerSideProps = async ({ query: { PROMO_CODE }, locale = 'en', req }: GetServerSidePropsContext) => {
  try {
    const SSRAPI = withSSRContext({ req });
    await SSRAPI.Auth.currentAuthenticatedUser();

    return {
      redirect: {
        destination: 'profile',
        statusCode: 303,
      },
    };
  } catch (error: unknown) {
    return {
      props: {
        ...(await serverSideTranslations(locale, ['login', 'components'])),
        PROMO_CODE: PROMO_CODE ? PROMO_CODE : null,
      },
    };
  }
};

export default Login;
