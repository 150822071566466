import { useState, FormEvent } from 'react';
import { Auth } from 'aws-amplify';
import Image from 'next/image';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';

import styles from './index.module.scss';
import warningIcon from '/public/icons/warning.svg';
import { getAuthErrorMessage } from '../../shared/utils';
import PasswordCreate from '../../components/PasswordCreate';
import { PASSWORD_RULES } from '../../constants';

type PasswordNewProps = {
  email: string;
  setContext: Function;
};

const PasswordNew = ({ email, setContext }: PasswordNewProps) => {
  const [resetCode, setResetCode] = useState<string>('');
  const [codeError, setCodeError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');
  const failingPasswordsCheck = PASSWORD_RULES.map(({ RULE }) => RULE.test(password)).includes(false);
  const disableForm = password !== confirmPassword || failingPasswordsCheck;
  const { t } = useTranslation('components');

  const forgotPasswordSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      return setConfirmPasswordError(t('PASSWORD_NEW.CONFIRM_PASSWORD_ERROR_MESSAGE'));
    }
    setConfirmPasswordError('');

    if (failingPasswordsCheck) {
      return setPasswordError(t('PASSWORD_NEW.MISMATCH_PASSWORD_ERROR_MESSAGE'));
    }
    setPasswordError('');

    await Auth.forgotPasswordSubmit(email, resetCode, String(confirmPassword ? confirmPassword : ''))
      .then(() => {
        toast.success(t('PASSWORD_NEW.RESET_PASSWORD_SUCCESS_MESSAGE'));

        return setContext('login');
      })
      .catch((error) => {
        const stringError = String(error);

        if (stringError.includes('ExpiredCodeException')) {
          setCodeError(t('PASSWORD_NEW.CODE_EXPIRED_ERROR_MESSAGE'));
          return setContext('forgotPassword');
        }

        if (stringError.includes('CodeMismatchException')) {
          return setCodeError(t('PASSWORD_NEW.CODE_MISMATCH_ERROR_MESSAGE'));
        }

        const errorMessage = getAuthErrorMessage(error);

        toast.error(errorMessage);
      });
  };

  return (
    <form onSubmit={(e) => forgotPasswordSubmit(e)} className={styles['new-password']}>
      <div className="relative">
        <label>
          {t('PASSWORD_NEW.RESEND_CODE_LABEL')}
          <input
            type="text"
            name="password"
            required
            value={resetCode}
            onChange={(e) => setResetCode(e.currentTarget.value)}
            autoComplete="off"
          />
        </label>
      </div>

      {codeError && (
        <div className="flex pb-4">
          <Image alt="Warning icon" src={warningIcon} />
          <p className="error  pl-4" dangerouslySetInnerHTML={{ __html: codeError }} />
        </div>
      )}

      <div className="relative">
        <PasswordCreate
          passwordError={passwordError}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          setConfirmPasswordError={setConfirmPasswordError}
          confirmPasswordError={confirmPasswordError}
          label="New password"
          hideShowPassword={false}
          showConfirmPassword
          setPasswordError={setPasswordError}
        />
      </div>
      <button className="primary" disabled={disableForm}>
        {t('PASSWORD_NEW.SUBMIT_CTA')}
      </button>
    </form>
  );
};

export default PasswordNew;
